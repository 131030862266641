<template>
  <div class="position-relative">
    <Notification
      :snackbar="isNotification"
      :message="notificationMessage"
      :key="notificationKey"
      @closeAlert="isNotification = false"
    />
    <img class="logo-container" src="@/assets/logo.svg" />
    <v-row class="no-gutters login-container">
      <!-- Left container -->
      <v-col class="col-3 image-container exact-center">
        <ImageSection />
      </v-col>
      <!-- Right container (form section) -->
      <v-col class="exact-center">
        <div class="form-container exact-center">
          <div class="form-width">
            <div class="font-weight-500 header">Email Verification</div>
            <div class="font-weight-500 login-header mt-7">Enter your Email Address</div>
            <div class="content">We need your email to verify</div>
            <!-- User type option -->
            <v-radio-group
              v-model="row"
              @change="togglePortal"
              row
              class="mt-7"
              style="font-size: 10px"
            >
              <v-radio label="Merchant" color="#ff003f" value="merchant"></v-radio>
              <v-radio label="Logistic" color="#ff003f" value="logistic"></v-radio>
            </v-radio-group>
            <!-- Form field -->
            <TextField
              label="Email"
              :required="true"
              :value="formData.email_address"
              @change="getFormData($event.toLowerCase(), 'email_address')"
              :errorMessages="emailErrors"
              :error="emailErrors && emailErrors.length > 0"
              @input="$v.formData.email_address.$touch()"
              @blur="$v.formData.email_address.$touch()"
              @onEnter="enterClicked"
              :key="emailKey"
            />
            <div
              class="justify-space-between width-100 align-center error-container mb-3"
            >
              <div class="error-message">{{ errorMessage }}</div>
              <div class="reset-password cursor-pointer" @click="goToLoginPage">
                Back to Login
              </div>
            </div>
            <div class="justify-end">
              <Button
                type="primary"
                label="Send"
                width="94px"
                @click="onSave"
                :disabled="isSubmitDisabled() || isLoading"
                :loading="isLoading"
              />
            </div>
          </div>
        </div>
        <div class="position-absolute login-rights">2021 Shiok All rights reserved</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import ImageSection from "../ImageSection.vue";
export default {
  name: "VerifyEmail",
  mixins: [validationMixin],
  components: {
    ImageSection,
  },
  validations: {
    formData: {
      email_address: { required, email },
    },
  },
  data() {
    return {
      isNotification: false,
      notificationMessage: "",
      notificationKey: 0,
      isLoading: false,
      row: "",
      errorMessage: "",
      formData: {
        email_address: "",
      },
      emailKey: 10,
    };
  },
  methods: {
    ...mapActions({
      adminForgetPassword: "admin/authentication/forgetPassword",
      merchantForgetPassword: "merchant/authentication/forgetPassword",
      logisticsForgetPassword: "logistics/authentication/forgetPassword",
    }),
    getFormData(value, fieldName) {
      this.formData[fieldName] = value;
      this.formData = { ...this.formData };
    },
    isSubmitDisabled() {
      return this.$v.$invalid;
    },
    goToLoginPage() {
      const type = this.$route.params.type;
      this.$router.push(`/auth/login/${type}`);
    },
    togglePortal(type) {
      this.row = type;
      this.$router.push(`/auth/verify-email/${type}`);
    },
    enterClicked() {
      if (!this.isSubmitDisabled()) {
        this.onSave();
      }
    },
    async onSave() {
      try {
        this.isLoading = true;
        if (this.row === "merchant") {
          await this.merchantForgetPassword(this.formData);
          this.isLoading = false;
        } else if (this.row === "logistic") {
          await this.logisticsForgetPassword(this.formData);
          this.isLoading = false;
        } else {
          await this.adminForgetPassword(this.formData);
          this.isLoading = false;
        }
        this.errorMessage = "";
        this.notificationMessage = "Password reset link has been sent to your email";
        this.isNotification = true;
        this.notificationKey += 1;
        this.formData.email_address = "";
        this.emailKey += 1;
      } catch (err) {
        const { data } = err.response;
        const { message } = data;
        this.errorMessage = message;
        this.isLoading = false;
      }
    },
  },
  created() {
    this.row = this.$route?.params?.type;
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email_address.$dirty) return errors;
      // !this.$v.formData.email_address.required && errors.push("Email is required.");
      !this.$v.formData.email_address.email && errors.push("Must be valid e-mail");
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
@import "@/styles/auth.scss";

.error-container {
  margin-top: -4px;
  padding-left: 5px;
}
.error-message {
  font-size: 12px;
  color: red;
}
</style>
